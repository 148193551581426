import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

export const useOffPlanAreaStore = create(
  devtools(
    immer((set, get) => ({
      searchName: "",
      searchSlug: "",
      areaJSON: [],
      showSuggestion:true,
      setAreaJSON: areaJSON =>
        set(state => {
          state.areaJSON = areaJSON
        }),
      setName: name =>
        set(state => {
          state.searchName = name
        }),
      setShowSuggestion: showSuggestion =>
        set(state => {
          state.showSuggestion = showSuggestion
        }),
      setSlug: slug =>
        set(state => {
          state.searchSlug = slug
          //   if (editSearchText) {
          const areaJSON = get()?.areaJSON
          const area = areaJSON?.filter(item => item.slug === slug)?.pop()
          state.searchSlug = slug
          state.searchName = area?.name || slug
          //   } else {
          // state.searchSlug = searchSlug
          //   }
        }),
        reset: () => {
          set(state => {
            state.searchName = ""
          }
          )}
    }))
  )
)
